// import {useEffect, useState} from 'react'

const Landing = () => {
    // const [orderID, setOrderID] = useState()

    // useEffect(() => {
    //     const url = window.location.href.split('/')
    //     const orderID = url[url.length - 1]
    //     setOrderID(orderID)
    // }, [])

    return (
        <div className="bg landing">
            <div className="container">
                <div className="logo"></div>
                <div className="welcome"></div>

                <div className="blocks">
                    <div className="block">
                        <div className="title">We support</div>
                        <div className="description">Zikoland is a social interactive e-commerce platform ,where
                            everyone works together to share the same value and passion for food and beverage.<br></br>
                            Our ever-growing community is gatherings, entertainment, one to one service ,and our
                            collaborations with Shanghai’s most restaurants and bars.
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">We select</div>
                        <div className="description">In order to guarantee the best quality, we try everything before we
                            offer it to you<br></br>
                            We work hard to source and select new & different products with trusted importers, so you
                            can have a wide range of quality goods without wasting your time or money.
                        </div>
                    </div>
                    <div className="block">
                        <div className="title">WE CARE</div>
                        <div className="description">By shortening the process between producers and customers, and
                            sharing the story of our products, we are able to benefit and connect customers and
                            producers.<br></br>
                            It’s our responsibility to offer competitive pricing.<br></br>
                            Our method of promoting is unique to Zikoland and ensures the prices are kept fair so
                            everyone can enjoy cooking at home!
                        </div>
                    </div>
                </div>


                {/* <button className="access"
                        // onClick={() => navigate(`/payment/${orderID}`)}
                >
                    Access the payment
                </button> */}
            </div>
        </div>
    )
}

export default Landing
