import { useEffect, useState, Fragment } from 'react'
import Landing from './Landing'

// import WechatJSSDK from 'wechat-jssdk';

// const wechatObj = new WechatJSSDK({
//   //set your oauth redirect url, defaults to localhost
//   "wechatRedirectUrl": "http://yourdomain.com/wechat/oauth-callback",
//   //"wechatToken": "wechat_token", //not necessary required
//   "appId": "wx06b0ecf1ae06ca36",
//   "appSecret": "e9ba6eed2a7293300d7d16c6917d23fe",
//   card: true, //enable cards
//   payment: true, //enable payment support
//   merchantId: '', //
// })

const getType = (type) => {
  switch (type) {
    case 'Kitchen':
      return ' kitchen'
    case 'Garden':
      return ' garden'
    case 'Farm':
      return ' farm'
    case 'Pet':
      return ' pet'
    case 'Cellar':
      return ' cellar'
    default:
      return ''
  }
}

function App() {
  const [isError, setError] = useState(false)
  const [noId, setNoId] = useState(false)
  const [nbId, setNbId] = useState()
  const [orderIsLoading, setOrderIsLoading] = useState(false)
  const [payIsLoading, setPayIsLoading] = useState(false)
  // const [isWX, setIsWX] = useState(true)
  // const [isWXReady, setIsWXReady] = useState(false)
  const [order, setOrder] = useState()

  //  var obj = new window.WxLogin({
  //     self_redirect:true,
  //     id:"login_container", 
  //     appid: process.env.REACT_APP_WX_APPID, 
  //     scope: "", 
  //     redirect_uri: "",
  //       state: "",
  //     style: "",
  //     href: ""
  //   });

  const goToPay = async () => {
    setPayIsLoading(true)

    if (typeof window.WeixinJSBridge !== "undefined") {

      setPayIsLoading(false)
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.REACT_APP_WX_APPID}&redirect_uri=${encodeURI(window.location.href)}&response_type=code&scope=snsapi_base&state=${nbId}#wechat_redirect`

      return
    } else {
      const h5payRes = await fetch(`${process.env.REACT_APP_API_URL}/orders/${nbId}/pay/h5`, { method: 'post' })

      const h5pay = await h5payRes.json()

      if (!h5payRes.ok) {
        setError(true)
        setPayIsLoading(false)
        return
      }

      setPayIsLoading(false)
      window.location.href = h5pay.h5_url;
    }
    setPayIsLoading(false)
  }

  const goWXPay = async (id, code) => {
      var headers = new Headers();
      headers.append( 'Content-Type', 'application/json');

      var body = new FormData();
      body.append("code",code);

      const wxpayRes = await fetch(`${process.env.REACT_APP_API_URL}/orders/${id}/pay/jsapi`, { method: 'post', headers, body: JSON.stringify({ code }) })

      const wxpay = await wxpayRes.json()

      if (!wxpayRes.ok) {
        // setError(true)
        setPayIsLoading(false)
        window.location.href = `${window.location.origin}/${id}`
        return
      }
    
      function onBridgeReady() { 

        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": wxpay.appid, //Official Account name, passed by the merchant 
            "timeStamp": `${wxpay.timestamp}`,//Timestamp, number of seconds since 1970 
            "nonceStr": `${wxpay.nonce_str}`, //Random string 
            "package": `prepay_id=${wxpay.prepay_id}`, 
            "signType": "RSA",//WeChat signature method:
            "paySign": wxpay.signature //WeChat signature
          },
          function(res) {
            if(res.err_msg === "get_brand_wcpay_request:ok" ) {
              setOrder(e => ({...e, paymentStatus: "paid" }))
              setPayIsLoading(false)
            } // The above method can be used to judge the value returned from the frontend. WeChat team reminds you that res.err_msg will return OK after users make payment successfully, but this value is not guaranteed to be absolute reliable.

            // if(res.err_msg === "get_brand_wcpay_request:cancel" ) {
            //   console.log('Cancelled')
            // }

            // if(res.err_msg === "get_brand_wcpay_request:fail" ) {
            //   setError(true)
            // }
          }
        );
      }

      if (typeof window.WeixinJSBridge === "undefined") {
        if( document.addEventListener ) { 
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) { 
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
  }

  useEffect(() => {
    const init = async () => {
      try {
        setOrderIsLoading(true)
        let orderID

        const url = new URL(window.location.href);

        let search = {}
        if (url.search) {
          let query = url.search.slice(1).split('&')

          query.forEach(e => {
            const param = e.split('=')

            search[param[0]] = param[1]
          })
        }

        if (search.code && search.state) {
          orderID = search.state

          goWXPay(search.state, search.code)

        } else if (url.pathname && url.pathname !== '/') {
          orderID = url.pathname.slice(1)
        }

        if (!orderID) {
          setError(true)
          setNoId(true)
          setOrderIsLoading(false)
          return
        }

        setNbId(orderID)

        const responseOrder = await fetch(`${process.env.REACT_APP_API_URL}/orders/${orderID}/public`)

        const dataOrder = await responseOrder.json()

        if (!responseOrder.ok || (dataOrder?.status === "discontinued" && dataOrder?.paymentStatus === "pending")) {
          setError(true)
          setOrderIsLoading(false)
          return
        }

        if (dataOrder.offer?.type === 'free_fall') {
          dataOrder.singleItems?.map(item => {
            for (const i in dataOrder.offer?.wechatGroup?.items) {
              if (item._id === dataOrder.offer?.wechatGroup?.items[i]._id) {
                item.price = getFFPrice(dataOrder.offer?.wechatGroup?.items[i]);
                break;
              }
            }
          })
        }

        if (dataOrder.offer?.type === 'bourse') {
          dataOrder.singleItems?.map(item => {
            item.price = getBoursePrice(dataOrder.offer?.wechatGroup?.items, dataOrder.offer?.wechatGroup?.bourses);
          })
        }

        setOrder(dataOrder)
        setOrderIsLoading(false)

      } catch (error) {
          setError(true)
          setOrderIsLoading(false)
      }
    }

    init()
  }, [])

  const displayNb = (nb) => {
    if (!nb) return '0.00'
    let value = new Intl.NumberFormat('en-US', {minimumFractionDigits: 2,}).format(nb)
    return value.replaceAll(/(\t)/gm, ' ').replaceAll(' ', ' ')
  }

  const getFFPrice = (item) => {
    if (!item) return undefined

    const sold = item?.stock - item?.actualStock
    const dividable = sold - (sold % item?.freeFall?.quantityTrigger)

    const dropedPrice =
      (dividable / item?.freeFall?.quantityTrigger) *
      item?.freeFall?.dropAmount

    if (item?.price - dropedPrice < item?.freeFall?.lowestPrice) {
      return item?.freeFall?.lowestPrice
    }

    return item?.price - dropedPrice
  }

  const getBouseMinMax = (bourse) => {
    let min = null
    let max = null

    for (let i = 0; i < bourse.length; i++) {
      if (min === null || bourse[i]?.from < min?.from) {
        min = bourse[i]
      }

      if (max === null || bourse[i]?.to > max?.from) {
        max = bourse[i]
      }
    }

    return { max, min }
  }

  const getBoursePrice = (items, bourse, index) => {
    if (!items) return '-'
    let totalPacks = 0

    for (const item of items) {
      totalPacks += item?.stock - item?.actualStock
    }

    const minmax = getBouseMinMax(bourse)

    if (totalPacks === 0) {
      return minmax?.min?.unitPrice
    }

    if (totalPacks > minmax.max.to) {
      return minmax?.max?.unitPrice
    }

    for (const price of bourse) {
      if (totalPacks >= price?.from && totalPacks <= price?.to) {
        return price?.unitPrice
      }
    }

    return !!index || index === 0 ? items[index]?.price : '-'
  }

  if (noId) {
    return <Landing />
  }

  return (
    <div className={`bg${orderIsLoading ? ' loading' : ''}${!!order ? getType(order?.community) : ''}${isError ? ' error' : ''}`}>

      <span className='loader'>Loading...</span>
      <span className='error-message'>An error occur</span>

      <div className='mainwrap'>
        <div className='window'>
          <div className="logo"></div>

          {order?.paymentStatus === 'paid' && <h3 className='end-title'>Payment success</h3>}
          {(order?.paymentStatus === 'ccl' || order?.paymentStatus === 'rfd') && <h3 className='end-title'>Order cancelled</h3>}

          <div className='order-id'>
            <span className='label'>Order ID</span>
            <span className='value'>{order?.orderID}</span>
          </div>

          {order?.paymentStatus === 'paid' &&
            <span className='end-text'>
              Thank you for your order!<br/>You're the best thing since<br/>"The Lord of the Rings"!
              <br />
              <br />
              感谢下单!<br/>《西游记》以外属您最棒啦!
            </span>
          }
          
          {(order?.paymentStatus === 'ccl' || order?.paymentStatus === 'rfd') &&
            <span className='end-text'>The order has been cancelled.</span>
          }

          {(order?.paymentStatus !== 'ccl' && order?.paymentStatus !== 'rfd' && order?.paymentStatus !== 'paid') &&
            <>
              <div className='total-price'>
                <span className='label'>Total payment</span>
                <span className='value'>¥ {displayNb(order?.actualAmount)}</span>
              </div>

              <div className='separator' />

              <span className='items-title'>
                Items
              </span>

              {order?.packs.filter(e => !!e?.amount).map((pack, i) => (
                <Item
                  key={i}
                  uri={`${process.env.REACT_APP_API_URL}/storage/pack-illustration/${pack?.mainPicture?.en?.uri}`} 
                  shortName={pack?.shortName}
                  total={displayNb(pack?.amount * pack?.price)}
                  name={pack?.name?.en}
                  qty={pack?.amount}
                />
              ))}

              {order?.singleItems.filter(e => !!e?.amount).map((item, i) => (
                <Item
                  isItem
                  key={i}
                  uri={`${process.env.REACT_APP_API_URL}/storage/product-cover/${item?.product?.mainPicture?.en?.uri}`} 
                  shortName={item?.shortName}
                  total={displayNb(item?.amount * item?.price)}
                  name={item?.product?.name?.en}
                  qty={item?.amount}
                />
              ))}

              <div className='fees wrap'>
                <span className='label'>Delivery fees</span>
                <span className='amount'>¥ {order?.deliveryFee}</span>
              </div>
            </>
          }
          <div className='test' />
        </div>

        <div className='space' />
      </div>

      {(order?.paymentStatus !== 'ccl' &&
        order?.paymentStatus !== 'rfd' &&
        order?.paymentStatus !== 'paid') &&
        <footer className='footer wrap'>
          <div className='container'>
            <div className='infos'>
              <span className='label'>Total</span>
              <span className='total'>¥ {displayNb(order?.actualAmount)}</span>
            </div>
            <button 
              onClick={goToPay}
              disabled={orderIsLoading || isError || payIsLoading}
              className='pay-button'
            >
              Pay
            </button>
          </div>   
        </footer>
      }
    </div>
  )
}

const Item = ({ uri, shortName, total, name, qty, isItem }) => {
  return (
    <Fragment>
      <div className='item wrap'>
        <div
          className='image'
          style={{
            backgroundImage: `url(${uri})`,
          }}
        />
        <div className='infos wrap'>
          {/* <div className='price wrap'>
            <span className='label'>{isItem ? 'Single item' : 'Pack'} {shortName}</span>
          </div> */}
          <span className='name'>{name}</span>
          <div className='detail wrap'>
            <span className='qty'>Quantity:&nbsp;&nbsp;{qty}</span>
            <span className='total'>¥{total}</span>
          </div>
        </div>
      </div>

      <div className='separator' />
    </Fragment>
  )
}


export default App
